import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from './Icon';

const Wrapper = styled.div`
	margin-top: 3em;

	&.horizontal {
		@media screen and (min-width: 960px) {
			display: grid;
			grid-template-columns: [col] 25% [col] 75%;
			grid-template-rows: [row] auto [row] auto;
		}
	}

	@media screen and (min-width: 1280px) {
		margin-top: 2em;
	}
`;

const Title = styled.h4`
	${(props) => `
	color: var(--${props.mode}-section-title-text-color);`}
	text-transform: uppercase;
	font-size: 1.04em;

	@media screen and (max-width: 959px) {
		margin: 0.8em auto 0.5em;
		max-width: 19em;
		text-align: center;
	}

	@media screen and (min-width: 960px) {
		grid-column: 2;
		${(props) => props.mode === 'vertical' ?
		`text-align: center;
		margin: 1.6em 0 1.3em;` :
		'margin: 0.8em 0 0 1.2em;'}
	}
`;

const Description = styled.p`
	${(props) => `
	color: var(--${props.mode}-section-text-color);`}
	margin: 0 2em;
	padding: 0;
	line-height: 1.5em;
	${(props) => props.mode === 'vertical' ?
		`margin: 0 auto;
		max-width: 18.5em;
		` :
		''}

	@media screen and (min-width: 960px) {
		grid-column: 2;
		margin: 0 1.3em;
		${(props) => props.mode === 'vertical' ?
		`margin: 0 auto;
		max-width: 19em;
		` :
		''}
	}
`;

const SectionItem = ({title, description, icon, mode}) =>
	<Wrapper className={mode}>
		<Icon
			mode={mode}
			name={icon} />
		<Title mode={mode}>{title}</Title>
		<Description mode={mode}>{description}</Description>
	</Wrapper>;

SectionItem.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	mode: PropTypes.string
};

SectionItem.defaultProps = {
	mode: 'horizontal'
};

export default SectionItem;
