import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Title = styled.h3`
	margin: 0.2em 0 0;
	padding-left: 0.85em;
	font-size: 2.3em;
	font-weight: normal;
	color: var(--section-info-title-text-color);

	@media screen and (min-width: 960px) {
		margin-top: 0.5em;
		font-size: 2.3em;
		padding-left: 2.05em;
	}

	@media screen and (min-width: 1280px) {
		padding-left: 1.45em;
	}
`;

const Description = styled.p`
	color: var(--section-info-text-color);
	padding: 1.2em 2em 0;
	margin: 0;
	line-height: 1.6;

	@media screen and (min-width: 960px) {
		padding: 1.8em 4.8em 0;
	}

	@media screen and (min-width: 1280px) {
		padding: 1.8em 3.5em 0;
	}
`;

const SectionInfo = ({title, description}) =>
	<Fragment>
		<Title>{title}</Title>
		<Description>{description}</Description>
	</Fragment>;

SectionInfo.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired
};

export default SectionInfo;
