import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ModalTrigger from './ModalTrigger';
import solutionDiagram from '../assets/diagrams/solution-diagram.svg';

const Wrapper = styled.section`
	background: var(--secondary-bg-color);
	padding-bottom: 5em;
	max-width: 1280px;
	margin: 0 auto;

	@media screen and (min-width: 1280px) {
		display: grid;
		grid-template-columns: repeat(2, [col] 50%);
		padding-bottom: 0;
	}
`;

const Button = styled(ModalTrigger)`
	cursor: pointer;
	background-color: var(--schedule-bg-color);
	color: var(--schedule-text-color);
	padding: 0.6em;
	display: block;
	width: 9.5em;
	text-align: center;
	margin: 0.6em auto 0;
	font-size: 1.3em;
	border-radius: 3px;

	@media screen and (min-width: 960px) {
		margin-top: 3.1em;
	}

	@media screen and (min-width: 1280px) {
		margin: -16.7em 0 0 7.8em;
		max-height: 1em;
	}
`;

const Left = styled.div`
	padding-top: 3.3em;

	@media screen and (min-width: 1280px) {
		background-color: var(--tertiary-bg-color);
		padding-bottom: 24.5em;
	}
`;

const Right = styled.div`
	padding-top: 2.3em;

	@media screen and (min-width: 1280px) {
		padding-top: 3.3em;
	}
`;

const Description = styled.h5`
	font-size: 1.7em;
	line-height: 1.33;
	font-weight: lighter;
	text-align: center;
	max-width: 13em;
	margin: 0 auto;
	color: var(--header-text-color);

	@media screen and (min-width: 960px) {
		max-width: 20em;
		font-size: 2em;
		margin-top: 1em;
		line-height: 1.37em;
	}

	@media screen and (min-width: 1280px) {
		margin-top: 7.6em;
		max-width: 14em;
		text-align: left;
		padding-left: 1.2em;
	}
`;

const Img = styled.img`
	display: block;
	margin: 0 auto;
	width: 96%;

	@media screen and (min-width: 960px) {
		width: 66%;
	}

	@media screen and (min-width: 1280px) {
		width: 76%;
	}
`;

const SystemSection = ({children}) =>
	<Wrapper>
		<Left>
			<Description>
				{children}
			</Description>
		</Left>
		<Right>
			<Img
				alt="Libretto System Founded by Jeff Coyle"
				src={solutionDiagram} />
		</Right>
		<Button>Schedule Meeting &raquo;</Button>
	</Wrapper>;

SystemSection.propTypes = {
	children: PropTypes.node.isRequired
};

export default SystemSection;
