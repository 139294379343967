import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
	padding: 3em 0 0;

	@media screen and (min-width: 960px) {
		padding: 8em 8em 0;
		grid-column: col / span 3;
	}
`;

const Title = styled.h2`
	margin: 0 auto;
	text-transform: uppercase;
	max-width: 10em;
	text-align: center;
	padding: 0.5em 0;
	font-size: 1.3em;
	font-weight: normal;
	${(props) => `
	background-color: var(--${props.section}-hero-bg-color);
	color: var(--${props.section}-hero-text-color);`}
`;

const Description = styled.p`
	margin: 2em auto 0;
	max-width: 14.4em;
	font-size: 1.55em;
	line-height: 1.3em;
	text-align: center;
	${(props) => `
	color: var(--${props.section}-hero-title-text-color);`}

	@media screen and (min-width: 960px) {
		margin-top: 1.5em;
		font-size: 2.1em;
		line-height: 1.4em;
		max-width: 22em;
	}

	@media screen and (min-width: 1280px) {
		max-width: 25em;
	}
`;

const SectionHero = ({title, children, section}) =>
	<Wrapper>
		<Title section={section}>{title}</Title>
		<Description section={section}>{children}</Description>
	</Wrapper>;

SectionHero.propTypes = {
	section: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired
};

export default SectionHero;
