import React, {PureComponent, Fragment} from 'react';
import Helmet from 'react-helmet';
import clearLogo from '../assets/images/clear-logo.png';
import GlobalStyle from '../styles/GlobalStyle';
import {SectionContainer} from '../components/Layout';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Section, {FirmValue, ClientValue, ClientValueMedia, FirmValueMedia} from '../components/Section';
import SectionWide from '../components/SectionWide';
import SectionHero from '../components/SectionHero';
import SectionInfo from '../components/SectionInfo';
import SectionItem from '../components/SectionItem';
import SystemSection from '../components/SystemSection';

class Index extends PureComponent {
	render() {
		let pageTitle = 'Libretto';
		let metaTags = [
			{name: 'description', content: 'Libretto'},
			{name: 'keywords', content: 'Wealth Management'},
			{property: 'og:title', pageTitle},
			{property: 'og:img', content: clearLogo},
			{property: 'twitter:img', content: clearLogo}
		];

		return <Fragment>
			<Helmet
				title={pageTitle}
				meta={metaTags}
			/>
			<GlobalStyle />
			<Header />
			<main>
				<SectionContainer className="secondary">
					<Section id="our-approach-section">
						<SectionHero
							section="approach"
							title="Our Approach">
							Create robust financial solutions for your clients while
							giving your firm a differentiated competitive advantage.
						</SectionHero>
						<ClientValueMedia />
						<ClientValue>
							<SectionInfo
								title="Client Value"
								description="Provide confidence in financial protection and capitalize on
									growth with comprehensive management of your clients' financial structures."
							/>
							<SectionItem
								icon="comprehensive management"
								title="Comprehensive Management"
								description="Libretto provides a systematized process that addresses all client
							assets, liabilities and objectives, enabling efficient design of
							highly tailored solutions."
							/>
							<SectionItem
								icon="robust solutions"
								title="Robust Solutions"
								description="Libretto enables you to strengthen confidence in client
							outcomes with the application of purpose-driven portfolio
							construction, hedges, insurance and wealth products."
							/>
							<SectionItem
								icon="confidence and trust"
								title="Confidence and Trust"
								description="With an intuitive advisor-client interface, clients come to
								understand the purpose of each asset in the portfolio and the need for other
								wealth products and services."
							/>
						</ClientValue>
						<FirmValueMedia />
						<FirmValue>
							<SectionInfo
								title="Firm Value"
								description="Drive improved revenue growth and margin expansion with a strong
							value proposition and technology-driven operating efficiencies."
							/>
							<SectionItem
								icon="differentiated value proposition"
								title="Differentiated Value Proposition"
								description="Built to manage any level of client complexity, Libretto provides
							a differentiated framework for addressing all aspects of a client's
							wealth and objectives."
							/>
							<SectionItem
								icon="streamlined process"
								title="Streamlined Priority-to-Trade process"
								description="Libretto enables scalable delivery of comprehensive wealth
							management with a systematized process that starts with a
							priority and ends with a trade directive."
							/>
							<SectionItem
								icon="improved financials"
								title="Improved Financials"
								description="Greater sales success, higher AUM, larger share of wallet and
							technology-driven operating efficiencies lead to enhanced
							compensation and firm valuation."
							/>
						</FirmValue>
					</Section>
				</SectionContainer>
				<SectionContainer className="primary">
					<SectionWide id="our-solution-section">
						<SectionHero
							section="solution"
							title="Our Solution">
							We deliver a differentiated platform and methodology
							that helps your clients succeed and your firm excel.
						</SectionHero>
						<SectionItem
							icon="software platform"
							mode="vertical"
							title="Software Platform"
							description="Built on the tenets of priorities-based
						planning, liability-driven investing and
						factor-level portfolio construction, our
						differentiated platform enables you to
						design robust financial solutions, enable lifelong
						prosperity and establish lasting client
						relationships."
						/>
						<SectionItem
							icon="change management"
							mode="vertical"
							title="Change Management"
							description="Empower sales and service professionals
						with professional training, support client
						delivery with white-label materials and
						augment CIO functions with in-depth
						research."
						/>
						<SectionItem
							icon="business process"
							mode="vertical"
							title="Business Process"
							description="Amplify your bottom line and client
						success with technology-enabled business
						processes that allow for quality-controlled
						delivery of differentiated wealth advice at
						scale."
						/>
					</SectionWide>
				</SectionContainer>
				<SectionContainer className="split">
					<SystemSection >
						Libretto&apos;s white-label wealth management solution
						integrates smoothly with existing systems.
					</SystemSection>
				</SectionContainer>
			</main>
			<Footer />
		</Fragment>;
	}
}

export default Index;
