import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.section`
	background: var(--main-bg-color);
	padding-bottom: 11.5em;
	max-width: 1280px;
	margin: 0 auto;

	@media screen and (min-width: 960px) {
		display: grid;
		padding-bottom: 5.5em;
		grid-template-columns: repeat(3, [col] 33.33%);
	}

	@media screen and (min-width: 1280px) {
		padding-bottom: 12em;
	}
`;

const SectionWide = ({id, children}) =>
	<Wrapper id={id}>
		{children}
	</Wrapper>;

SectionWide.propTypes = {
	children: PropTypes.node.isRequired,
	id: PropTypes.string.isRequired
};

export default SectionWide;
