import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import businessProcess from '../assets/icons/icon-business-process.svg';
import changeManagement from '../assets/icons/icon-change-management.svg';
import comprehensiveManagement from '../assets/icons/icon-comprehensive-management.svg';
import confidenceAndTrust from '../assets/icons/icon-confidence-and-trust.svg';
import differentiatedValueProposition from '../assets/icons/icon-differentiated-value-proposition.svg';
import improvedFinancials from '../assets/icons/icon-improved-financials.svg';
import robustSolutions from '../assets/icons/icon-robust-solutions.svg';
import softwarePlatform from '../assets/icons/icon-software-platform.svg';
import streamlinedProcess from '../assets/icons/icon-streamlined-process.svg';

const ICONS = {
	'business process': businessProcess,
	'change management': changeManagement,
	'comprehensive management': comprehensiveManagement,
	'confidence and trust': confidenceAndTrust,
	'differentiated value proposition': differentiatedValueProposition,
	'improved financials': improvedFinancials,
	'robust solutions': robustSolutions,
	'software platform': softwarePlatform,
	'streamlined process': streamlinedProcess
};

const Wrapper = styled.div`
	@media screen and (min-width: 960px) {
		grid-column: 1;
		grid-row: 1 / span 2;
		${(props) => props.mode === 'horizontal' ?
		'text-align: right;' :
		'text-align: center;'}
	}
`;

const IconElement = styled.span`
	height: 5em;
	margin: 2em auto 0;
	display: block;
	${(props) => props.name === 'change management' ?
		'width: 7.5em;' :
		'width: 5em;'}

	@media screen and (min-width: 960px) {
		margin: 1em 0 0;
		display: inline-block;
	}
`;

const Icon = ({name, mode}) =>
	<Wrapper mode={mode}>
		<IconElement name={name}>
			<img
				src={ICONS[name]}
				alt={name}
			/>
		</IconElement>
	</Wrapper>;

Icon.propTypes = {
	name: PropTypes.string.isRequired,
	mode: PropTypes.string
};

Icon.defaultProps = {
	mode: 'horizontal'
};

export default Icon;
