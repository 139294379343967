import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import mountains from '../assets/images/mountains.jpg';
import plane from '../assets/images/plane.jpg';

const Wrapper = styled.section`
	background: var(--secondary-bg-color);
	padding-bottom: 5em;
	max-width: 1280px;
	margin: 0 auto;

	@media screen and (min-width: 1280px) {
		display: grid;
		grid-template-columns: repeat(2, [col] 50%);
		padding-bottom: 9em;
	}
`;

const SectionStyles = styled.div`
	margin: 0 auto;
	padding: 2.3em 0;
	background-color: var(--section-bg-color);

	@media screen and (max-width: 959px) {
		max-width: 22.5em;
		padding-bottom: 4em;
	}

	@media screen and (min-width: 960px) {
		max-width: 46em;
		height: 37em;
	}

	@media screen and (min-width: 1280px) {
		grid-row: auto;
		width: calc(100% - 7em);
		height: auto;
	}
`;

export const ClientValue = styled(SectionStyles)`
	box-shadow: 0 0 0.5em 0 var(--section-shadow-color);

	@media screen and (min-width: 1280px) {
		box-shadow: 2px 0 0.5em 0 var(--section-shadow-color);
		border-radius: 0 16px 16px 0;
		grid-column: 2;
		margin: 5.7em 7em 0 0;
	}
`;

export const FirmValue = styled(SectionStyles)`
	box-shadow: 0 0 0.5em 0 var(--section-shadow-color);

	@media screen and (min-width: 1280px) {
		box-shadow: -2px 0 0.5em 0 var(--section-shadow-color);
		border-radius: 16px 0 0 16px;
		grid-column: 1;
		margin: 4.2em 0 0 7em;
	}
`;

const SectionMedia = styled.div`
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: cover;
	margin: 3.2em auto 0;

	@media screen and (max-width: 959px) {
		max-width: 22.5em;
		height: 22.5em;
	}

	@media screen and (min-width: 960px) {
		max-width: 46em;
		height: 46em;
	}

	@media screen and (min-width: 1280px) {
		max-width: 100%;
		height: 38.5em;
		grid-row: auto;
		width: calc(100% - 7em);
	}
`;

export const ClientValueMedia = styled(SectionMedia)`
	background-image: url(${plane});

	@media screen and (min-width: 1280px) {
		margin: 5.7em 0 0 7em;
		grid-column: 1;
	}
`;

export const FirmValueMedia = styled(SectionMedia)`
	background-image: url(${mountains});

	@media screen and (max-width: 959px) {
		margin-top: 4em;
	}

	@media screen and (min-width: 960px) {
		margin-top: 5.2em;
	}

	@media screen and (min-width: 1280px) {
		margin: 4.2em 7em 0 0;
		grid-column: 2;
		grid-row: 3;
	}
`;

const Section = ({id, children}) =>
	<Wrapper id={id}>
		{children}
	</Wrapper>;

Section.propTypes = {
	children: PropTypes.node.isRequired,
	id: PropTypes.string.isRequired
};

export default Section;
